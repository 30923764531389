import React, { FC, useCallback, FormEvent } from "react";
import swal from "sweetalert";

import Header from "../partials/Header";
import Newsletter from "../partials/Newsletter";
import Footer from "../partials/Footer";
import Seo from "../partials/Seo";
import NewButton from "../partials/NewButton";
import Input from "../partials/Input";
import classNames from "clsx";

const Contact: FC = () => {
  const handleFormSubmit = useCallback((evt: FormEvent<HTMLFormElement>) => {
    const formEl = evt.target as HTMLFormElement;
    const formData = new FormData(formEl);

    const body = [
      ["form-name", formEl.getAttribute("name")],
      ["honeypot", "rest-field"],
      ...(formData.entries() as any),
    ]
      .map(
        ([key, value]) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(value)
      )
      .join("&");

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body,
    })
      .then(() => {
        swal(
          "Thank you for getting in touch!",
          "We appreciate you contacting us.\nWe'll get back in touch with you soon!\nHave a great day!",
          "success"
        );

        formEl.reset();
      })
      .catch((err) => {
        swal("Oops!", err.message, "error");
      });

    evt.preventDefault();
  }, []);

  return (
    <>
      <Seo title="Contact" />

      <div className="flex flex-col min-h-screen overflow-hidden">
        {/*  Site header */}
        <Header />

        {/*  Page content */}
        <main className="flex-grow">
          <section className="relative">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
              <div className="pt-32 pb-12 md:pt-40 md:pb-20">
                {/* Page header */}
                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                  <h1 className="h1 mb-4" data-aos="fade-up">
                    How can we help you?
                  </h1>
                  <p
                    className="text-xl text-gray-400"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    If you have any questions or need help, please fill out the
                    form below. We do our best to respond shortly.
                  </p>
                </div>

                {/* Contact form */}
                <form
                  name="contact"
                  className="max-w-xl mx-auto"
                  onSubmit={handleFormSubmit}
                >
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    className="w-full mb-4"
                    placeholder="Enter your name"
                    label="Name"
                    required
                  />

                  <Input
                    id="email"
                    name="email"
                    type="text"
                    className="w-full mb-4"
                    placeholder="Enter your email address"
                    label="Email"
                    required
                  />

                  <Input
                    id="subject"
                    name="subject"
                    type="text"
                    className="w-full mb-4"
                    placeholder="How can we help you?"
                    label="Subject"
                    required
                  />

                  <div className="flex flex-col">
                    <label htmlFor="message" className="mb-1 px-4 color-white">
                      Message
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      rows={8}
                      className={classNames(
                        "w-full text-gray-300 mb-4 resize-none",
                        "py-3 px-4",
                        "box-border",
                        "text-brand-light leading-none",
                        "bg-black/20",
                        "border border-brand-tertiary/10",
                        "rounded-[.625rem]",
                        "outline-none",
                        "transition-colors",
                        "placeholder-brand-placeholder",
                        "focus:bg-black/20 focus:border-brand-tertiary/[.15]",
                        "transition ease-in-out duration-200"
                      )}
                      placeholder="Write your message"
                    />
                  </div>

                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          className="form-checkbox"
                          required
                        />
                        <span className="text-gray-400 ml-2">
                          I agree to the{" "}
                          <a
                            href="/privacy"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-brand-accent hover:underline"
                          >
                            privacy policy
                          </a>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mt-8">
                    <div className="w-full px-24">
                      <NewButton type="submit" className="w-full">
                        Send
                      </NewButton>
                    </div>
                  </div>

                  <div hidden>
                    <label>
                      Don't fill this out: <input name="rest-field" />
                    </label>
                  </div>
                </form>
              </div>
            </div>
          </section>

          {/*  Bottom CTA */}
          {/* <CtaContact /> */}
          <Newsletter />
        </main>

        {/*  Site footer */}
        <Footer />
      </div>
    </>
  );
};

export default Contact;
